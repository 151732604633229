import React, { FC } from 'react'
import { Flex, Text } from '@moonpig/launchpad-components'
import { ThemeInterface, colorValue } from '@moonpig/launchpad-theme'
import styled from 'styled-components'
import { IconSystemTick } from '@moonpig/launchpad-assets'
import { MenuItem as ReachMenuItem } from '@reach/menu-button'
import {
  DROPDOWN_ITEM_BORDER_RADIUS,
  DROPDOWN_ITEM_HEIGHT,
  DROPDOWN_ITEM_ICON_SIZE,
} from './constants'

const StyledDropdownMenuItemWrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: block;
  user-select: none;
  cursor: pointer;
  color: ${colorValue('colorTextHeadline')};
  border: 2px solid
    ${({ isSelected }) =>
      isSelected ? colorValue('colorInteractionSelectedState') : 'transparent'};
  background-color: ${({ isSelected }) =>
    isSelected ? colorValue('colorBackground03') : 'transparent'};
  outline: ${({ isSelected }) => (isSelected ? 'none' : 'initial')};

  &:first-child {
    border-radius: ${`${DROPDOWN_ITEM_BORDER_RADIUS} ${DROPDOWN_ITEM_BORDER_RADIUS} 0 0`};
  }

  &:last-child {
    border-radius: ${`0 0 ${DROPDOWN_ITEM_BORDER_RADIUS} ${DROPDOWN_ITEM_BORDER_RADIUS}`};
  }

  &:not(:last-child)::after {
    position: absolute;
    left: -2px;
    bottom: -2px;
    display: ${({ isSelected }) => (isSelected ? 'none' : 'block')};
    content: '';
    width: calc(100% + 4px);
    height: 1px;
    background-color: ${colorValue('colorBorder03')};
  }

  &:hover {
    ${({ isSelected, theme }: { isSelected: boolean; theme: ThemeInterface }) =>
      !isSelected &&
      `color: ${colorValue('colorInteractionButtonHover')({ theme })};
      border-color: ${colorValue('colorInteractionButtonHover')({ theme })};
      background-color: ${colorValue('colorBackground06')({ theme })};`}

    &::after {
      display: none;
    }
  }
`

const StyledIconSystemTick = styled(IconSystemTick)`
  color: ${colorValue('colorInteractionSelectedState')};
`

const StyledIconWrapper = styled(Flex)`
  align-items: center;
  width: ${DROPDOWN_ITEM_ICON_SIZE};

  svg {
    width: 100%;
  }
`

const DropdownMenuItemButton = ReachMenuItem

export const DropdownMenuItem: FC<{
  item: {
    value: string
    title: string
    subtitle?: string
    icon?: JSX.Element
  }
  isSelected: boolean
  onSelectHandler: (value: string) => void
}> = ({ item, isSelected, onSelectHandler }) => {
  const { value, title, subtitle, icon } = item

  return (
    <StyledDropdownMenuItemWrapper key={value} isSelected={isSelected}>
      <DropdownMenuItemButton
        data-testid={`shared-products-dropdown-item-${value}`}
        onSelect={() => onSelectHandler(value)}
      >
        <Flex minHeight={DROPDOWN_ITEM_HEIGHT} pl={4} pr={5} py={4}>
          <StyledIconWrapper>{icon}</StyledIconWrapper>
          <Flex
            flexDirection="column"
            justifyContent="center"
            flexGrow={3}
            pl={7}
          >
            <Text typography="typeButtonLabel">{title}</Text>
            {subtitle && <Text mb={0}>{subtitle}</Text>}
          </Flex>
          {isSelected && (
            <Flex justifyContent="center" alignItems="center" ml={4}>
              <StyledIconSystemTick width={24} />
            </Flex>
          )}
        </Flex>
      </DropdownMenuItemButton>
    </StyledDropdownMenuItemWrapper>
  )
}
