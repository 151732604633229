/*
  EXPERIMENTAL COMPONENT
  This is a clone of Launchpad's Modal component
  https://github.com/Moonpig/launchpad/tree/master/packages/components/src/Modal
  Used only for the browse-modal-animation experiment
*/

import React, {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
// eslint-disable-next-line import/no-namespace
import * as Dialog from '@radix-ui/react-dialog'
import {
  breakpoint,
  debounce,
  styled,
  useIsoLayoutEffect,
  keyframes,
  css,
} from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemCross } from '@moonpig/launchpad-assets'
import { ColorsInterface, colorValue } from '@moonpig/launchpad-theme'
import { Box, IconButton } from '@moonpig/launchpad-components'

type PaletteColor = keyof ColorsInterface

type ModalProps = {
  /** The aria label value for the modal's content */
  label: string
  /** Determines if the modal is visible */
  isOpen?: boolean
  /** Determines whether a close button renders in the top right corner of the modal */
  hasCloseButton?: boolean
  /** Function to be called when a user attempts to dismiss the modal */
  onDismiss?: () => void
  /** Test id for the modal overlay */
  testId?: string
  /** Label for the close button */
  closeButtonLabel?: string
  /** Determines whether page is partially visible on mobile */
  mobilePagePeek?: boolean
  /** Enables animation effect for the modal */
  enableAnimation?: boolean
  /** An optional component to render in the banner of the modal */
  bannerContent?: React.ReactNode
  /** Optionally pass a ref to the trigger button so it can receive focus after the modal is closed */
  triggerRef?: React.RefObject<HTMLElement>
  /** Optionally overwrite the default background and close button colours */
  colours?: {
    background?: PaletteColor
    bannerBackground?: PaletteColor
    closeButton?: {
      default?: PaletteColor
      focus?: PaletteColor
      active?: PaletteColor
    }
  }
  /** Append some spacing at the bottom of the modal content.
   * Helps account for mobile browsers shifting the content upward when the modal
   * contains a form */
  addContentSpacing?: boolean
}

type MobileViewProps = {
  mobilePagePeek?: boolean
}

const MODAL_FROM_BREAKPOINT = 'md'
const CLOSE_BUTTON_HEIGHT = 56
const BANNER_HEIGHT = 70
const PAGE_PEEK_HEIGHT = 16
const DEFAULT_BACKGROUND_COLOUR = 'colorBackground01'
const CONTENT_SPACING_FACTOR = 0.3

/* --- KEYFRAMES --- */

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`
const slideIn = (y: string) => keyframes`
  0% {
    transform: translateY(${y});
  }
  100% {
    transform: translateY(0);
  }
`
const slideOut = (y: string) => keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(${y});
  }
`

const fadeInAnimation = css`
  animation: ${fadeIn} 250ms ease-in-out;
`
const fadeOutAnimation = css`
  animation: ${fadeOut} 200ms ease-in-out both;
`
const slideInAnimation = (y: string, t: number, bezier: string) => css`
  animation: ${slideIn(y)} ${t}ms ${bezier};
`
const slideOutAnimation = (y: string, t: number, bezier: string) => css`
  animation: ${slideOut(y)} ${t}ms ${bezier} both;
`

/* --- OVERLAY --- */
const StyledOverlay = styled.div<
  MobileViewProps & { enableAnimation?: boolean; dismissed?: boolean }
>`
  z-index: 2000;
  ${({ mobilePagePeek, theme }) =>
    s({
      bgcolor: mobilePagePeek
        ? 'rgba(0, 0, 0, 0.6)'
        : {
            xs: colorValue('colorBackground01')({ theme }),
            [MODAL_FROM_BREAKPOINT]: 'rgba(0, 0, 0, 0.6)',
          },
    })};

  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  display: flex;
  ${({ enableAnimation, dismissed }) => {
    if (enableAnimation) {
      return dismissed ? fadeOutAnimation : fadeInAnimation
    }
  }}
`

/* --- BANNER --- */

const StyledModalBanner = styled.div<{
  bgColour?: PaletteColor
  mobilePagePeek?: boolean
}>`
  ${({ bgColour, theme, mobilePagePeek }) =>
    s({
      bgcolor: bgColour
        ? colorValue(bgColour)({ theme })
        : colorValue(DEFAULT_BACKGROUND_COLOUR)({ theme }),
      p: 3,
      borderRadius: {
        xs: mobilePagePeek ? '16px 16px 0 0' : 0,
        [MODAL_FROM_BREAKPOINT]: 0,
      },
    })}

  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: ${BANNER_HEIGHT}px;
  min-height: ${CLOSE_BUTTON_HEIGHT}px;
`

/* --- MODAL --- */

type ModalContentProps = ComponentProps<typeof Dialog.Content> &
  MobileViewProps & {
    viewportHeight: number
    bgColour?: PaletteColor
    enableAnimation?: boolean
    dismissed?: boolean
  }

const StyledModalContent = styled.div<ModalContentProps>`
  ${({ mobilePagePeek, viewportHeight, bgColour, enableAnimation, theme }) =>
    s({
      alignSelf: mobilePagePeek && {
        xs: 'flex-end',
        [MODAL_FROM_BREAKPOINT]: 'center',
      },
      bgcolor: bgColour
        ? colorValue(bgColour)({ theme })
        : colorValue(DEFAULT_BACKGROUND_COLOUR)({ theme }),
      maxHeight:
        mobilePagePeek &&
        viewportHeight +
          (enableAnimation ? CLOSE_BUTTON_HEIGHT : PAGE_PEEK_HEIGHT),
      mx: 'auto',
      mt: mobilePagePeek ? 'auto' : { [MODAL_FROM_BREAKPOINT]: 'auto' },
      mb: mobilePagePeek ? { [MODAL_FROM_BREAKPOINT]: 'auto' } : 'auto',
      borderRadius: {
        xs: mobilePagePeek ? '16px 16px 0 0' : 0,
        [MODAL_FROM_BREAKPOINT]: 0,
      },
    })};
  -webkit-overflow-scrolling: touch;
  ${({ enableAnimation, dismissed }) => {
    if (enableAnimation) {
      return dismissed
        ? slideOutAnimation('100%', 200, 'ease-in')
        : slideInAnimation('100%', 300, 'ease-out')
    }
  }}

  ${breakpoint(MODAL_FROM_BREAKPOINT)} {
    ${({ enableAnimation, dismissed }) => {
      if (enableAnimation) {
        return dismissed
          ? slideOutAnimation('40px', 200, 'ease-in-out')
          : slideInAnimation('40px', 250, 'ease-in-out')
      }
    }}
  }
`

/* --- CLOSE BUTTON --- */

const StyledCloseButton = styled.div<{
  bgColour?: PaletteColor
}>`
  ${({ bgColour, theme }) =>
    s({
      bgcolor: bgColour
        ? colorValue(bgColour)({ theme })
        : colorValue(DEFAULT_BACKGROUND_COLOUR)({ theme }),
      p: 3,
    })}

  display: flex;
  flex-direction: row-reverse;
  height: ${CLOSE_BUTTON_HEIGHT}px;
`

/* --- CONTENT --- */

type DialogContentWrapperProps = MobileViewProps & {
  hasCloseButton: boolean
  viewportHeight: number
}

const calculateHeightOffset = (
  hasCloseButton?: boolean,
  mobilePagePeek?: boolean,
) =>
  [
    hasCloseButton ? CLOSE_BUTTON_HEIGHT : 0,
    mobilePagePeek ? PAGE_PEEK_HEIGHT : 0,
  ].reduce((acc, height) => acc + height, 0)

const StyledModalBody = styled(Box)<DialogContentWrapperProps>`
  ${({ hasCloseButton }) =>
    s({
      pt: hasCloseButton ? 0 : { xs: 6, [MODAL_FROM_BREAKPOINT]: 10 },
    })};

  max-height: ${({ viewportHeight }) => viewportHeight}px;

  max-width: 100vw;
  ${breakpoint(MODAL_FROM_BREAKPOINT)} {
    max-width: calc(100vw - ${({ theme }) => theme.spacing[10]}px);
  }
  overflow-y: auto;
  outline: none;
  margin: auto;

  ${s({
    px: { xs: 5, [MODAL_FROM_BREAKPOINT]: 10 },
    pb: { xs: 5, [MODAL_FROM_BREAKPOINT]: 10 },
  })}

  > * {
    max-width: 100%;
  }
`

export const Modal: FC<React.PropsWithChildren<ModalProps>> = ({
  label,
  isOpen = false,
  hasCloseButton,
  onDismiss,
  testId = 'lp-components-modal',
  children,
  closeButtonLabel = 'Close',
  mobilePagePeek,
  enableAnimation,
  bannerContent,
  triggerRef,
  colours,
  addContentSpacing,
}) => {
  const [dismissed, setDismissed] = useState(false)
  const [viewportHeight, setViewportHeight] = useState(0)
  const heightOffset = useMemo(
    () => calculateHeightOffset(hasCloseButton, mobilePagePeek),
    [hasCloseButton, mobilePagePeek],
  )
  const getExtraContentSpacing = useCallback(
    /* istanbul ignore next */ () => {
      const spacing =
        heightOffset +
        (addContentSpacing ? window.innerHeight * CONTENT_SPACING_FACTOR : 0)

      return spacing
    },
    [addContentSpacing, heightOffset],
  )

  const handleWindowResize = useCallback(
    /* istanbul ignore next */ () =>
      debounce(
        /* istanbul ignore next */ () => {
          if (
            window.visualViewport &&
            window.visualViewport.height < viewportHeight
          ) {
            /* instanbul ignore next */ setViewportHeight(
              window.visualViewport.height + getExtraContentSpacing(),
            )
          }
        },
        150,
      ),
    [viewportHeight, getExtraContentSpacing],
  )

  useIsoLayoutEffect(() => {
    if (viewportHeight === 0) {
      setViewportHeight(window.innerHeight - heightOffset)
    }
  }, [viewportHeight, heightOffset])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    window.visualViewport?.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
      window.visualViewport?.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  const closeModal = useCallback(() => {
    setDismissed(true)
    setViewportHeight(0)
    if (onDismiss && !enableAnimation) {
      onDismiss()
    }
  }, [enableAnimation, onDismiss])

  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (!isOpen && triggerRef?.current) {
      triggerRef.current.focus()
    }
  }, [isOpen, triggerRef])

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay data-testid={`${testId}-overlay`} />
        <StyledOverlay
          mobilePagePeek={mobilePagePeek}
          data-testid={`${testId}-overlay-styles`}
          dismissed={dismissed}
          enableAnimation={enableAnimation}
          {...(enableAnimation && {
            onAnimationEnd: () => {
              if (dismissed && onDismiss) {
                onDismiss()
              }
            },
          })}
        >
          <Dialog.Content
            asChild
            onEscapeKeyDown={closeModal}
            onPointerDownOutside={closeModal}
          >
            <StyledModalContent
              mobilePagePeek={mobilePagePeek}
              viewportHeight={viewportHeight}
              bgColour={colours?.background}
              data-testid={`${testId}`}
              aria-modal={isOpen}
              aria-label={label}
              enableAnimation={enableAnimation}
              dismissed={dismissed}
            >
              {bannerContent ? (
                <StyledModalBanner
                  bgColour={colours?.bannerBackground || colours?.background}
                  mobilePagePeek={mobilePagePeek}
                >
                  {bannerContent}
                  {hasCloseButton && (
                    <IconButton
                      icon={IconSystemCross}
                      label={closeButtonLabel}
                      onClick={closeModal}
                      colours={colours?.closeButton}
                    />
                  )}
                </StyledModalBanner>
              ) : (
                <>
                  {hasCloseButton && (
                    <StyledCloseButton
                      bgColour={
                        colours?.bannerBackground || colours?.background
                      }
                    >
                      <IconButton
                        icon={IconSystemCross}
                        label={closeButtonLabel}
                        onClick={closeModal}
                        colours={colours?.closeButton}
                      />
                    </StyledCloseButton>
                  )}
                </>
              )}
              <StyledModalBody
                hasCloseButton={!!hasCloseButton}
                data-testid={`${testId}-body`}
                viewportHeight={viewportHeight}
              >
                {children}
              </StyledModalBody>
            </StyledModalContent>
          </Dialog.Content>
        </StyledOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
