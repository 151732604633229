import { Flex, Text } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { useProductsLocaleText } from '../../locale'

type Props = {
  label: string
  discount: number
  code: string
}

export const PromotionRow: FC<Props> = ({ label, discount, code }) => {
  const localiseText = useProductsLocaleText()

  return (
    <Flex
      alignItems="center"
      borderBottom={'1px solid'}
      borderColor={'colorBorder03'}
      py={4}
    >
      <Text
        as="p"
        flex={1}
        fontWeight="bold"
        color={'colorTextHeadline'}
        mb={0}
      >
        {label}
      </Text>
      <Text as="p" mb={0}>
        {localiseText('multicard.discount', { discount })}{' '}
        {code && 'with code '}
        <strong>{code}</strong>
      </Text>
    </Flex>
  )
}
