import React, { FC, useEffect } from 'react'
import { PrimaryButton, SecondaryButton } from '@moonpig/launchpad-components'
import {
  breakpoint,
  breakpointDown,
  css,
  styled,
} from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { spacingPx } from '@moonpig/launchpad-theme'
import { useProductStore } from '../../contexts/product'
import { isCardProduct } from '../../utils/isCardProduct'
import { ModuleContext, ModuleButtons } from '../types'
import { useBasket } from '../../contexts/basket'
import { useCreateButtons } from './useCreateButtons'
import { useProductRequiresPersonalisationQuery } from '../../queries/useProductRequiresPersonalisationQuery'

const CTA_CONTAINER_MIN_HEIGHT = {
  desktop: '52px',
  mobile: '85px',
}

type StyledCtaContainerProps = {
  isCard: boolean
}

const StyledCtaContainer = styled.div<StyledCtaContainerProps>`
  width: 100%;
  min-height: ${CTA_CONTAINER_MIN_HEIGHT.desktop};

  ${breakpoint('md')} {
    ${({ isCard }) =>
      !isCard &&
      css`
        position: sticky;
        bottom: -${spacingPx(10)};
        margin-top: -${spacingPx(6)};
        z-index: 1;
        box-shadow: -4px 0 0 0 #fff, 4px 0 0 0 #fff;
        padding: ${spacingPx(9)} 0 ${spacingPx(10)} 0;
        background: linear-gradient(
          to top,
          rgba(255, 255, 255, 1) 80%,
          rgba(255, 255, 255, 0.1)
        );
      `}
  }

  ${s({
    borderTop: { xs: 1, md: 0 },
    borderColor: { xs: 'palette.eclipse.c140', md: 'none' },
    boxShadow: { xs: 4, md: 0 },
    mb: { xs: 0, md: 6 },
  })}

  ${breakpointDown('md')} {
    bottom: 0;
    left: 0;
    position: fixed;
    background-color: white;
    z-index: 3;
    min-height: ${CTA_CONTAINER_MIN_HEIGHT.mobile};
    ${s({
      p: 6,
    })}
    justify-content: center;
  }
`

const StyledCtaContainerAnimated = styled.div`
  display: flex;
  align-items: center;
  animation: fadeIn 200ms forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

type Props = {
  module: ModuleButtons
  context: ModuleContext
}

export const ButtonsModule: FC<Props> = ({
  context: { pageType },
  module: { productType },
}) => {
  const [{ product }] = useProductStore()
  const [{ error: basketState }, { setLoading }] = useBasket()
  const isCard = isCardProduct(product.category.department)
  const requiresEditing = useProductRequiresPersonalisationQuery({
    productId: product.id,
    skip: !isCard,
  })

  const { primaryButton, secondaryButton, loading, skipEditor } =
    useCreateButtons({
      pageType,
      productType,
    })

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  return (
    <StyledCtaContainer data-testid="module-buttons" isCard={isCard}>
      {skipEditor ? (
        <>
          {
            /* istanbul ignore next */ !requiresEditing?.loading && (
              <StyledCtaContainerAnimated>
                {secondaryButton && (
                  <SecondaryButton
                    width={1 / 2}
                    onClick={() => secondaryButton.onClick()}
                    disabled={basketState}
                    className="add-to-basket-cta"
                  >
                    {secondaryButton.text}
                  </SecondaryButton>
                )}
                <PrimaryButton
                  width={
                    requiresEditing?.value === false && secondaryButton
                      ? 1 / 2
                      : 1
                  }
                  ml={
                    requiresEditing?.value === false && secondaryButton ? 6 : 0
                  }
                  onClick={primaryButton.onClick}
                  disabled={basketState}
                >
                  {primaryButton.text}
                </PrimaryButton>
              </StyledCtaContainerAnimated>
            )
          }
        </>
      ) : (
        <PrimaryButton
          width={1}
          onClick={() => primaryButton.onClick()}
          disabled={basketState}
        >
          {primaryButton.text}
        </PrimaryButton>
      )}
    </StyledCtaContainer>
  )
}
