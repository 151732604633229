import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import type { ModuleContext, ModuleMemberPricingHeader } from '../types'

type Props = {
  module: ModuleMemberPricingHeader
  context: ModuleContext
}

export const MemberPricingHeaderModule: FC<Props> = () => {
  return (
    <Box data-testid="module-member-pricing-header">Member Pricing Header</Box>
  )
}
