import { useProductsLocaleText } from '../../locale'
import { Module } from '../types'

const defaultRightColumnSingleCardMobileModules: Module[] = [
  {
    __typename: 'ModuleVariants',
    type: 'radio',
  },
  { __typename: 'ModuleDispatch' },
  { __typename: 'ModuleQuantity' },
  { __typename: 'ModuleMoreInfo' },
  { __typename: 'ModulePeopleAlsoViewed' },
  { __typename: 'ModuleButtons' },
]

const defaultRightColumnSingleCardDesktopModules: Module[] = [
  {
    __typename: 'ModuleVariants',
    type: 'radio',
  },
  { __typename: 'ModuleDispatch' },
  { __typename: 'ModuleQuantity' },
  { __typename: 'ModuleButtons' },
  { __typename: 'ModuleMoreInfo' },
]

const defaultRightColumnSingleCardVariantDesktopModules: Module[] = [
  {
    __typename: 'ModuleVariants',
    type: 'radio',
  },
  { __typename: 'ModuleDispatch' },
  { __typename: 'ModuleQuantity' },
  { __typename: 'ModuleButtons' },
  { __typename: 'ModuleMoreInfo' },
]

const defaultRightColumnSingleCardVariantMobileModules: Module[] = [
  {
    __typename: 'ModuleVariants',
    type: 'radio',
  },
  { __typename: 'ModuleDispatch' },
  { __typename: 'ModuleQuantity' },
  { __typename: 'ModuleMoreInfo' },
  { __typename: 'ModulePeopleAlsoViewedVariant' },
  { __typename: 'ModuleButtons' },
]

const memberPricingHeaderModule: Module[] = [
  {
    __typename: 'ModuleMemberPricingHeader',
  },
]

export const createCardMobileConfiguration = ({
  localise,
  isMemberPriceEnabled = false,
}: {
  localise: ReturnType<typeof useProductsLocaleText>
  isMemberPriceEnabled?: boolean
}): Module[] => {
  const rightColumnSingleCardMobileModules = isMemberPriceEnabled
    ? memberPricingHeaderModule.concat(
        defaultRightColumnSingleCardMobileModules,
      )
    : defaultRightColumnSingleCardMobileModules

  return [
    { __typename: 'ModuleImages' },
    {
      __typename: 'ModuleGroupCardTabs',
      tabs: [
        {
          title: localise('tabs.card'),
          label: localise('tabs.card_label'),
          type: 'SINGLE',
          modules: rightColumnSingleCardMobileModules,
        },
        {
          title: localise('tabs.group_card'),
          label: localise('tabs.group_card_label'),
          type: 'GROUP',
          modules: [
            {
              __typename: 'ModuleList',
              title: localise('tabs.group_card_list_title'),
              items: [
                localise('tabs.group_card_list_item_1'),
                localise('tabs.group_card_list_item_2'),
                localise('tabs.group_card_list_item_3'),
                localise('tabs.group_card_list_item_4'),
              ],
            },
            { __typename: 'ModuleVariants', type: 'dropdown' },
            { __typename: 'ModulePeopleAlsoViewed' },
            { __typename: 'ModuleButtons', productType: 'groupCard' },
          ],
        },
      ],
      meta: { intent: 'change tab' },
    },
  ]
}

export const createCardDesktopConfiguration = ({
  localise,
  isMemberPriceEnabled = false,
}: {
  localise: ReturnType<typeof useProductsLocaleText>
  isMemberPriceEnabled?: boolean
}): Module[] => {
  const rightColumnSingleCardDesktopModules = isMemberPriceEnabled
    ? memberPricingHeaderModule.concat(
        defaultRightColumnSingleCardDesktopModules,
      )
    : defaultRightColumnSingleCardDesktopModules

  return [
    {
      __typename: 'ModuleTwoColumn',
      left: [
        { __typename: 'ModuleImages' },
        { __typename: 'ModulePeopleAlsoViewed' },
      ],
      right: [
        {
          __typename: 'ModuleGroupCardTabs',
          tabs: [
            {
              title: localise('tabs.card'),
              label: localise('tabs.card_label'),
              type: 'SINGLE',
              modules: rightColumnSingleCardDesktopModules,
            },
            {
              title: localise('tabs.group_card'),
              label: localise('tabs.group_card_label'),
              type: 'GROUP',
              modules: [
                {
                  __typename: 'ModuleList',
                  title: localise('tabs.group_card_list_title'),
                  items: [
                    localise('tabs.group_card_list_item_1'),
                    localise('tabs.group_card_list_item_2'),
                    localise('tabs.group_card_list_item_3'),
                    localise('tabs.group_card_list_item_4'),
                  ],
                },
                { __typename: 'ModuleVariants', type: 'dropdown' },
                { __typename: 'ModuleButtons', productType: 'groupCard' },
              ],
            },
          ],
          meta: { intent: 'change tab' },
        },
      ],
      ratio: '50/50',
    },
  ]
}

export const createCardVariantMobileConfiguration = ({
  localise,
  isMemberPriceEnabled = false,
}: {
  localise: ReturnType<typeof useProductsLocaleText>
  isMemberPriceEnabled?: boolean
}): Module[] => {
  const rightColumnSingleCardVariantMobileModules = isMemberPriceEnabled
    ? memberPricingHeaderModule.concat(
        defaultRightColumnSingleCardVariantMobileModules,
      )
    : defaultRightColumnSingleCardVariantMobileModules

  return [
    { __typename: 'ModuleImages' },
    {
      __typename: 'ModuleGroupCardTabs',
      tabs: [
        {
          title: localise('tabs.card'),
          label: localise('tabs.card_label'),
          type: 'SINGLE',
          modules: rightColumnSingleCardVariantMobileModules,
        },
        {
          title: localise('tabs.group_card'),
          label: localise('tabs.group_card_label'),
          type: 'GROUP',
          modules: [
            {
              __typename: 'ModuleList',
              title: localise('tabs.group_card_list_title'),
              items: [
                localise('tabs.group_card_list_item_1'),
                localise('tabs.group_card_list_item_2'),
                localise('tabs.group_card_list_item_3'),
                localise('tabs.group_card_list_item_4'),
              ],
            },
            { __typename: 'ModuleVariants', type: 'dropdown' },
            { __typename: 'ModulePeopleAlsoViewedVariant' },
            { __typename: 'ModuleButtons', productType: 'groupCard' },
          ],
        },
      ],
      meta: { intent: 'change tab' },
    },
  ]
}

export const createCardVariantDesktopConfiguration = ({
  localise,
  isMemberPriceEnabled = false,
}: {
  localise: ReturnType<typeof useProductsLocaleText>
  isMemberPriceEnabled?: boolean
}): Module[] => {
  const rightColumnSingleCardVariantDesktopModules = isMemberPriceEnabled
    ? memberPricingHeaderModule.concat(
        defaultRightColumnSingleCardVariantDesktopModules,
      )
    : defaultRightColumnSingleCardVariantDesktopModules

  return [
    {
      __typename: 'ModuleTwoColumn',
      left: [{ __typename: 'ModuleImages' }],
      right: [
        {
          __typename: 'ModuleGroupCardTabs',
          tabs: [
            {
              title: localise('tabs.card'),
              label: localise('tabs.card_label'),
              type: 'SINGLE',
              modules: rightColumnSingleCardVariantDesktopModules,
            },
            {
              title: localise('tabs.group_card'),
              label: localise('tabs.group_card_label'),
              type: 'GROUP',
              modules: [
                {
                  __typename: 'ModuleList',
                  title: localise('tabs.group_card_list_title'),
                  items: [
                    localise('tabs.group_card_list_item_1'),
                    localise('tabs.group_card_list_item_2'),
                    localise('tabs.group_card_list_item_3'),
                    localise('tabs.group_card_list_item_4'),
                  ],
                },
                { __typename: 'ModuleVariants', type: 'dropdown' },
                { __typename: 'ModuleButtons', productType: 'groupCard' },
              ],
            },
          ],
          meta: { intent: 'change tab' },
        },
      ],
      ratio: '50/50',
    },
    { __typename: 'ModulePeopleAlsoViewedVariant' },
  ]
}
