import {
  ScreenViewScreenData,
  createScreenViewEvent,
} from '@moonpig/web-core-analytics'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import { Store } from '@moonpig/web-core-types'
import { isCardProduct } from '../utils/isCardProduct'
import type { ProductInfoProduct } from '../types'

type ScreenViewGAEventProps = {
  product: ProductInfoProduct
  store: Store
}

type ProductData = {
  product_brand: string
  product_category: string
  product_id: string
  product_name: string
  product_variant: string
  product_price: number
  product_quantity: 1
  product_orientation?: string
}

export const createProductDetailsScreenView = ({
  store,
  product,
}: ScreenViewGAEventProps) => {
  const screenClass = 'product details | popup'
  const screenData: ScreenViewScreenData = {
    renderType: 'client',
    documentReferrer: document.location.href,
    documentTitle: `${BRAND_LABEL} | ${product.title}`.toLowerCase(),
    documentUrl:
      `${document.location.origin}/${store.id}/${product.category.slug}/p/${product.slug}/${product.id}/`.toLowerCase(),
  }

  let product_orientation: string | undefined
  if (
    isCardProduct(product.category.department) &&
    typeof product.isLandscape === 'boolean'
  ) {
    // istanbul ignore next
    product_orientation = product.isLandscape ? 'landscape' : 'portrait'
  }

  const addonProducts = product.variants
    .map(v => v.addons)
    .flat()
    .reduce<ProductData[]>((acc, addon) => {
      if (addon?.inStock) {
        acc.push({
          product_brand: BRAND_LABEL.toLowerCase(),
          product_category: product.category.department.toLowerCase(),
          product_id: addon.sku,
          product_name: addon.title,
          product_variant: 'add on',
          product_price: addon.price.centAmount / 100,
          product_quantity: 1,
        })
      }
      return acc
    }, [])

  return createScreenViewEvent({
    screenClass,
    screenName: `${screenClass} | ${product.title}`.substring(0, 100),
    screenData,
    productData: [
      {
        product_brand: BRAND_LABEL.toLowerCase(),
        product_category: product.category.department.toLowerCase(),
        product_id: product.id,
        product_name: product.title,
        product_variant: product.masterVariant.title,
        product_price: product.masterVariant.price.centAmount / 100,
        product_quantity: 1,
        product_orientation,
      },
      ...addonProducts,
    ],
  })
}
