import { useLuxAddData } from '@moonpig/web-core-utils'
import { useExperiments } from '@moonpig/web-core-experiments'

export const useLuxAddExperimentData = (
  additionalData: { name: string; value: string }[],
) => {
  const useNewIAExperiment =
    useExperiments('search-use-new-ia-index-v3') || 'false'

  useLuxAddData([
    {
      name: 'search-use-new-ia-index-v3',
      value: useNewIAExperiment,
    },
    ...additionalData,
  ])
}
