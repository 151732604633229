import React, { FC, useEffect, useMemo, useState } from 'react'
import { isSessionStorageAvailable } from '@moonpig/web-core-utils'
import { useStore } from '@moonpig/web-core-stores'
import { useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { dispatcherContext, eventContext } from './Context'
import { Dispatcher, ProductModalClosed, ProductModalShown } from './types'
import { ProductModal } from '../../components/ProductModal'
import type {
  AddedToBasketEvent,
  ProductContext,
  ProductInfoProduct,
  UpsellProduct,
} from '../../types'
import { OnConfigureModulesCallback } from '../../modules/types'
import { useDefaultModuleConfiguration } from '../../modules/configurations'
import { useRecentlyViewedDispatcher } from '../recentlyViewed'
import { createProductDetailsScreenView } from '../../analytics'

type State = {
  index?: number
  product?: ProductInfoProduct
  upsellProduct?: UpsellProduct
  groupCardProject?: string
  isOpen: boolean
  configuration: {
    showProductTabs: boolean
    showStickyCta: boolean
  }
  onAddToBasket: (event: AddedToBasketEvent) => void
}

const EMPTY_STATE = {
  isOpen: false,
  configuration: {
    showProductTabs: false,
    showStickyCta: false,
  },
  onAddToBasket: /* istanbul ignore next*/ () => {},
}

export const ProductModalProvider: FC<
  React.PropsWithChildren<{
    context: ProductContext
    onConfigureModules?: OnConfigureModulesCallback
  }>
> = ({ context, children, onConfigureModules }) => {
  const { addToRecentlyViewed } = useRecentlyViewedDispatcher()
  const configureModules = useDefaultModuleConfiguration(context)
  const { store } = useStore()
  const { trackGAEventOnce } = useTrackGAEventOnce()

  const [state, setState] = useState<State>(EMPTY_STATE)
  const [event, setEvent] = useState<
    ProductModalClosed | ProductModalShown | null
  >(null)

  const handleModalVisibility = (eventType: 'open' | 'close', id = '') => {
    const url = new URL(window.location.href)

    if (eventType === 'open') {
      if (isSessionStorageAvailable()) {
        window.sessionStorage.setItem('mnpg_product_id', id)
      }
      url.searchParams.set('productId', id)
      window.history.pushState({ productId: id }, '', url.toString())
    }

    if (eventType === 'close') {
      if (isSessionStorageAvailable()) {
        sessionStorage.removeItem('mnpg_product_id')
      }
      window.history.back()
    }
  }

  const actions: Dispatcher = useMemo(() => {
    return {
      show: params => {
        setState({ ...params, isOpen: true })
        setEvent({ type: 'PRODUCT_MODAL_SHOWN' })
        addToRecentlyViewed(params)
        handleModalVisibility('open', params.product.id)
      },
      close: () => {
        setState(EMPTY_STATE)
        setEvent({ type: 'PRODUCT_MODAL_CLOSED' })
        handleModalVisibility('close')
      },
    }
  }, [addToRecentlyViewed])

  const listener = () => {
    const url = new URL(window.location.href)
    const productId = url.searchParams.get('productId')
    const sessionProductId = isSessionStorageAvailable()
      ? window.sessionStorage.getItem('mnpg_product_id')
      : /* istanbul ignore next */ ''

    /* istanbul ignore else */
    if (!productId || sessionProductId !== productId) {
      setState({ ...state, isOpen: false })
      setEvent({ type: 'PRODUCT_MODAL_CLOSED' })
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', listener)

    return () => {
      window.removeEventListener('popstate', listener)
    }
  })

  useEffect(() => {
    if (state.isOpen && state.product) {
      trackGAEventOnce(
        createProductDetailsScreenView({ store, product: state.product }),
      )
    }
  }, [state.isOpen, store, state.product, trackGAEventOnce])

  return (
    <dispatcherContext.Provider value={actions}>
      <eventContext.Provider value={event}>
        {children}
        {state.product && (
          <ProductModal
            {...state}
            product={state.product}
            upsellProduct={state.upsellProduct}
            productContext={context}
            onConfigureModules={onConfigureModules ?? configureModules}
            onDismiss={() => {
              setState(EMPTY_STATE)
              setEvent({ type: 'PRODUCT_MODAL_CLOSED' })
              handleModalVisibility('close')
            }}
          />
        )}
      </eventContext.Provider>
    </dispatcherContext.Provider>
  )
}
