import { useIsMobile, match } from '@moonpig/web-shared-utils'
import { useStoreId } from '@moonpig/web-core-stores'
import { useRouter } from '@moonpig/web-core-routing'
import { ProductInfoProduct } from '../../types'
import { isCardProduct } from '../../utils/isCardProduct'
import { Module } from '../types'
import { useProductsLocaleText } from '../../locale'
import {
  createCardMultiBuyDesktopConfiguration,
  createCardMultiBuyMobileConfiguration,
  createCardMultiBuyVariantDesktopConfiguration,
  createCardMultiBuyVariantMobileConfiguration,
} from './MultiBuyConfiguration'
import {
  createCardDesktopConfiguration,
  createCardMobileConfiguration,
  createCardVariantDesktopConfiguration,
  createCardVariantMobileConfiguration,
} from './GroupCardsConfiguration'
import {
  createGroupCardProjectDesktopConfiguration,
  createGroupCardProjectMobileConfiguration,
} from './GroupCardProjectConfiguration'

export const useDefaultModuleConfiguration = (context: {
  flags: Record<string, boolean>
}) => {
  const storeId = useStoreId()
  const localise = useProductsLocaleText()
  const isMobile = useIsMobile()
  const router = useRouter()

  return ({ product }: { product: ProductInfoProduct }): Module[] => {
    const isGroupCardProject =
      !!router.getCurrentRoute<'search'>()?.params?.groupCardProject

    const isMemberPriceEnabled = context.flags['loyalty-enable-member-pricing']

    const conditions = {
      isCard: isCardProduct(product.category.department),
      isMobile,
      isGroupCardProject,
      storeId,
      isVariant: context.flags['enable-image-carousel-variant'],
    }

    return match(conditions)
      .with(
        { isCard: false }, // prettier-ignore
        () => [], // prettier-ignore
      )
      .with(
        { isGroupCardProject: true, isMobile: true,  }, // prettier-ignore
        () => createGroupCardProjectMobileConfiguration(), // prettier-ignore
      )
      .with(
        { isGroupCardProject: true, isMobile: false, }, // prettier-ignore
        () => createGroupCardProjectDesktopConfiguration(), // prettier-ignore
      )
      .with(
        { storeId: 'uk', isMobile: true, isVariant: true }, // prettier-ignore
        () => createCardVariantMobileConfiguration({localise, isMemberPriceEnabled}), // prettier-ignore
      )
      .with(
        { storeId: 'uk', isMobile: false, isVariant: true }, // prettier-ignore
        () => createCardVariantDesktopConfiguration({localise, isMemberPriceEnabled}), // prettier-ignore
      )
      .with(
        { storeId: 'uk', isMobile: true }, // prettier-ignore
        () => createCardMobileConfiguration({localise, isMemberPriceEnabled}), // prettier-ignore
      )
      .with(
        { storeId: 'uk', isMobile: false }, // prettier-ignore
        () => createCardDesktopConfiguration({localise, isMemberPriceEnabled}), // prettier-ignore
      )
      .with(
        { isMobile: true, isVariant: true }, // prettier-ignore
        () => createCardMultiBuyVariantMobileConfiguration({localise, storeId, isMemberPriceEnabled}), // prettier-ignore
      )
      .with(
        { isMobile: false, isVariant: true }, // prettier-ignore
        () => createCardMultiBuyVariantDesktopConfiguration({localise, storeId, isMemberPriceEnabled}), // prettier-ignore
      )
      .with(
        { isMobile: false, }, // prettier-ignore
        () => createCardMultiBuyDesktopConfiguration({localise, storeId, isMemberPriceEnabled}), // prettier-ignore
      )
      .with(
        { isMobile: true, }, // prettier-ignore
        () => createCardMultiBuyMobileConfiguration({localise, storeId, isMemberPriceEnabled}), // prettier-ignore
      )
      .otherwise(/* istanbul ignore next */ () => [])
  }
}
