import { Region } from '@moonpig/web-core-types'
import {
  MULTI_CARD_CSV_LINK,
  MULTI_CARD_LEARN_MORE_LINK,
} from '@moonpig/web-core-brand/config'
import { useProductsLocaleText } from '../../locale'
import { Module } from '../types'
import { E_CARD_SIZE_ID } from '../../constants'

const defaultRightColumnSingleCardMobileModules: Module[] = [
  { __typename: 'ModuleVariants', type: 'radio' },
  { __typename: 'ModuleDispatch' },
  { __typename: 'ModuleMoreInfo' },
  { __typename: 'ModulePeopleAlsoViewed' },
  { __typename: 'ModuleButtons' },
]

const defaultRightColumnSingleCardDesktopModules: Module[] = [
  { __typename: 'ModuleVariants', type: 'radio' },
  { __typename: 'ModuleDispatch' },
  { __typename: 'ModuleButtons' },
  { __typename: 'ModuleMoreInfo' },
]

const defaultRightColumnSingleCardVariantMobileModules: Module[] = [
  { __typename: 'ModuleVariants', type: 'radio' },
  { __typename: 'ModuleDispatch' },
  { __typename: 'ModuleMoreInfo' },
  { __typename: 'ModulePeopleAlsoViewedVariant' },
  { __typename: 'ModuleButtons' },
]

const defaultRightColumnSingleCardVariantDesktopModules: Module[] = [
  { __typename: 'ModuleVariants', type: 'radio' },
  { __typename: 'ModuleDispatch' },
  { __typename: 'ModuleButtons' },
  { __typename: 'ModuleMoreInfo' },
]

const memberPricingHeaderModule: Module[] = [
  {
    __typename: 'ModuleMemberPricingHeader',
  },
]

export const createCardMultiBuyMobileConfiguration = ({
  localise,
  storeId,
  isMemberPriceEnabled = false,
}: {
  localise: ReturnType<typeof useProductsLocaleText>
  storeId: Region
  isMemberPriceEnabled?: boolean
}): Module[] => {
  const rightColumnSingleCardMobileModules = isMemberPriceEnabled
    ? memberPricingHeaderModule.concat(
        defaultRightColumnSingleCardMobileModules,
      )
    : defaultRightColumnSingleCardMobileModules

  return [
    { __typename: 'ModuleImages' },
    {
      __typename: 'ModuleMultiBuyTabs',
      tabs: [
        {
          title: localise('tabs.single_card'),
          label: localise('tabs.single_card_label'),
          modules: rightColumnSingleCardMobileModules,
        },
        {
          title: localise('tabs.multi_card'),
          label: localise('tabs.multi_card_label'),
          modules: [
            { __typename: 'ModuleQuantity' },
            {
              __typename: 'ModuleVariants',
              type: 'dropdown',
              unavailableVariants: [E_CARD_SIZE_ID],
            },
            { __typename: 'ModulePrice' },
            { __typename: 'ModulePromotions' },
            { __typename: 'ModuleButtons' },
            {
              __typename: 'ModuleLink',
              title: localise('multicard.how_to_send_to_different_addresses'),
              href: `/${storeId}${MULTI_CARD_LEARN_MORE_LINK}`,
              meta: {
                intent: 'view multi-address info',
              },
            },
            {
              __typename: 'ModuleLink',
              title: localise('multicard.download_csv_template'),
              href: MULTI_CARD_CSV_LINK[storeId],
              meta: {
                intent: 'download csv template',
              },
            },
          ],
        },
      ],
      meta: {
        intent: 'change quantity',
      },
    },
  ]
}

export const createCardMultiBuyDesktopConfiguration = ({
  localise,
  storeId,
  isMemberPriceEnabled = false,
}: {
  localise: ReturnType<typeof useProductsLocaleText>
  storeId: Region
  isMemberPriceEnabled?: boolean
}): Module[] => {
  const rightColumnSingleCardDesktopModules = isMemberPriceEnabled
    ? memberPricingHeaderModule.concat(
        defaultRightColumnSingleCardDesktopModules,
      )
    : defaultRightColumnSingleCardDesktopModules

  return [
    {
      __typename: 'ModuleTwoColumn',
      left: [
        { __typename: 'ModuleImages' },
        { __typename: 'ModulePeopleAlsoViewed' },
      ],
      right: [
        {
          __typename: 'ModuleMultiBuyTabs',
          tabs: [
            {
              title: localise('tabs.single_card'),
              label: localise('tabs.single_card_label'),
              modules: rightColumnSingleCardDesktopModules,
            },
            {
              title: localise('tabs.multi_card'),
              label: localise('tabs.multi_card_label'),
              modules: [
                { __typename: 'ModuleQuantity' },
                {
                  __typename: 'ModuleVariants',
                  type: 'dropdown',
                  unavailableVariants: [E_CARD_SIZE_ID],
                },
                { __typename: 'ModulePrice' },
                { __typename: 'ModulePromotions' },
                { __typename: 'ModuleButtons' },
                {
                  __typename: 'ModuleLink',
                  title: localise(
                    'multicard.how_to_send_to_different_addresses',
                  ),
                  href: `/${storeId}${MULTI_CARD_LEARN_MORE_LINK}`,
                  meta: {
                    intent: 'view multi-address info',
                  },
                },
                {
                  __typename: 'ModuleLink',
                  title: localise('multicard.download_csv_template'),
                  href: MULTI_CARD_CSV_LINK[storeId],
                  meta: {
                    intent: 'download csv template',
                  },
                },
              ],
            },
          ],
          meta: {
            intent: 'change quantity',
          },
        },
      ],
      ratio: '50/50',
    },
  ]
}

export const createCardMultiBuyVariantMobileConfiguration = ({
  localise,
  storeId,
  isMemberPriceEnabled = false,
}: {
  localise: ReturnType<typeof useProductsLocaleText>
  storeId: Region
  isMemberPriceEnabled?: boolean
}): Module[] => {
  const rightColumnSingleCardVariantMobileModules = isMemberPriceEnabled
    ? memberPricingHeaderModule.concat(
        defaultRightColumnSingleCardVariantMobileModules,
      )
    : defaultRightColumnSingleCardVariantMobileModules

  return [
    { __typename: 'ModuleImages' },
    {
      __typename: 'ModuleMultiBuyTabs',
      tabs: [
        {
          title: localise('tabs.single_card'),
          label: localise('tabs.single_card_label'),
          modules: rightColumnSingleCardVariantMobileModules,
        },
        {
          title: localise('tabs.multi_card'),
          label: localise('tabs.multi_card_label'),
          modules: [
            { __typename: 'ModuleQuantity' },
            {
              __typename: 'ModuleVariants',
              type: 'dropdown',
              unavailableVariants: [E_CARD_SIZE_ID],
            },
            { __typename: 'ModulePrice' },
            { __typename: 'ModulePromotions' },
            {
              __typename: 'ModuleLink',
              title: localise('multicard.how_to_send_to_different_addresses'),
              href: `/${storeId}${MULTI_CARD_LEARN_MORE_LINK}`,
              meta: {
                intent: 'view multi-address info',
              },
            },
            {
              __typename: 'ModuleLink',
              title: localise('multicard.download_csv_template'),
              href: MULTI_CARD_CSV_LINK[storeId],
              meta: {
                intent: 'download csv template',
              },
            },
            { __typename: 'ModulePeopleAlsoViewedVariant' },
            { __typename: 'ModuleButtons' },
          ],
        },
      ],
      meta: {
        intent: 'change quantity',
      },
    },
  ]
}

export const createCardMultiBuyVariantDesktopConfiguration = ({
  localise,
  storeId,
  isMemberPriceEnabled = false,
}: {
  localise: ReturnType<typeof useProductsLocaleText>
  storeId: Region
  isMemberPriceEnabled?: boolean
}): Module[] => {
  const rightColumnSingleCardVariantDesktopModules = isMemberPriceEnabled
    ? memberPricingHeaderModule.concat(
        defaultRightColumnSingleCardVariantDesktopModules,
      )
    : defaultRightColumnSingleCardVariantDesktopModules

  return [
    {
      __typename: 'ModuleTwoColumn',
      left: [{ __typename: 'ModuleImages' }],
      right: [
        {
          __typename: 'ModuleMultiBuyTabs',
          tabs: [
            {
              title: localise('tabs.single_card'),
              label: localise('tabs.single_card_label'),
              modules: rightColumnSingleCardVariantDesktopModules,
            },
            {
              title: localise('tabs.multi_card'),
              label: localise('tabs.multi_card_label'),
              modules: [
                { __typename: 'ModuleQuantity' },
                {
                  __typename: 'ModuleVariants',
                  type: 'dropdown',
                  unavailableVariants: [E_CARD_SIZE_ID],
                },
                { __typename: 'ModulePrice' },
                { __typename: 'ModulePromotions' },
                { __typename: 'ModuleButtons' },
                {
                  __typename: 'ModuleLink',
                  title: localise(
                    'multicard.how_to_send_to_different_addresses',
                  ),
                  href: `/${storeId}${MULTI_CARD_LEARN_MORE_LINK}`,
                  meta: {
                    intent: 'view multi-address info',
                  },
                },
                {
                  __typename: 'ModuleLink',
                  title: localise('multicard.download_csv_template'),
                  href: MULTI_CARD_CSV_LINK[storeId],
                  meta: {
                    intent: 'download csv template',
                  },
                },
              ],
            },
          ],
          meta: {
            intent: 'change quantity',
          },
        },
      ],
      ratio: '50/50',
    },
    { __typename: 'ModulePeopleAlsoViewedVariant' },
  ]
}
