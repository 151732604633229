import React, { type FC } from 'react'
import { Box, Pill } from '@moonpig/launchpad-components'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { useExperiment } from '@moonpig/web-core-experiments'
import { ProductInfoWithQuery } from '../ProductInfoWithQuery'
import { createModalSizeChangeGAEventDetails } from '../../analytics'
import {
  AddedToBasketEvent,
  ProductContext,
  ProductInfoProduct,
  UpsellProduct,
} from '../../types'
import { isCardProduct } from '../../utils/isCardProduct'
import { transformPill } from '../../utils/transformPill'
import { ProductStoreProvider } from '../../contexts/product'
import { useProductInitialVariantSelectionState } from '../../utils/useProductInitialVariantSelectionState'
import { ProductTrackingProvider } from '../../contexts/tracking'
import { OnConfigureModulesCallback } from '../../modules/types'
import { ModuleLayoutContainer } from '../../modules/LayoutContainer/ModuleLayoutContainer'
import { ScrollToTop } from './ScrollToTop'
import { Modal } from './ExperimentModal/Modal'

type Props = {
  index?: number
  product: ProductInfoProduct
  upsellProduct?: UpsellProduct
  groupCardProject?: string
  configuration: {
    showProductTabs: boolean
    showStickyCta: boolean
  }
  onConfigureModules?: OnConfigureModulesCallback
  productContext: ProductContext
  isOpen: boolean
  onAddToBasket: (event: AddedToBasketEvent) => void
  onDismiss: () => void
}

export const ProductModal: FC<Props> = ({
  index,
  product,
  upsellProduct,
  groupCardProject,
  configuration,
  productContext,
  isOpen,
  onAddToBasket,
  onDismiss,
  onConfigureModules,
}) => {
  const { variantKey } = useProductInitialVariantSelectionState()

  const isCard = product.category && isCardProduct(product.category.department)
  const isTshirt = product.category?.id === 12

  const pill = product.primaryProductPill
    ? transformPill(product.primaryProductPill)
    : undefined

  const banner = (
    <Box>
      {isCard && !!pill && (
        <Box ml={6}>
          <Box mr={{ xs: 4, md: 6 }}>
            <Pill
              data-testid="card-banner-pill"
              label={pill.displayLabel}
              variant={pill.displayVariant}
            />
          </Box>
        </Box>
      )}
    </Box>
  )

  const modalAnimationEnabled =
    useExperiment('browse-modal-animation') === 'variant'

  return (
    <Modal
      label={product?.title}
      isOpen={isOpen}
      hasCloseButton
      closeButtonLabel="Close"
      onDismiss={onDismiss}
      testId="quick-view"
      bannerContent={banner}
      {...(modalAnimationEnabled && {
        enableAnimation: true,
        mobilePagePeek: true,
      })}
    >
      <div
        data-testid="taggstar-metadata"
        data-page-type="product-details-modal"
        data-product-id={product.id}
      />
      <ProductStoreProvider
        initialProduct={product}
        initialVariantKey={variantKey}
        upsellProduct={upsellProduct}
        productContext={productContext}
      >
        <ProductTrackingProvider
          pageLocation="product details"
          pageComponent="popup"
        >
          <ScrollToTop>
            <ModuleLayoutContainer
              onConfigureModules={onConfigureModules}
              context={productContext}
            >
              <ProductInfoWithQuery
                product={product}
                productIndex={index}
                onAddToBasket={e =>
                  onAddToBasket({ ...e, index: e.productIndex })
                }
                onVariantChange={(variantValue: string) => {
                  trackGAEvent(
                    createModalSizeChangeGAEventDetails(variantValue),
                  )
                }}
                showTitle={!isCard && !isTshirt}
                showMoreInfo
                showProductTabs={configuration.showProductTabs}
                hideStickyCta={!configuration.showStickyCta}
                pageLocation={productContext.location}
                groupCardProject={groupCardProject}
                isFromModal
                pageType={productContext.pageType}
              />
            </ModuleLayoutContainer>
          </ScrollToTop>
        </ProductTrackingProvider>
      </ProductStoreProvider>
    </Modal>
  )
}
