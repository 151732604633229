/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect, useState } from 'react'
import { Box, Flex, Image, SimpleCarousel } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { spacing, theme } from '@moonpig/launchpad-theme'
import { FavouriteButton } from '../FavouriteButton'

const StyledImage = styled(Image)<{ p?: number }>`
  object-fit: contain;
  ${({ p }) => s({ p })}
`

const StyledCarousel = styled(SimpleCarousel)`
  padding: 0;
  & > div > ul {
    gap: 16px;
  }
`

const StyledImageContainer = styled(Box)`
  ${s({
    borderRadius: 4,
    overflow: 'hidden',
    bgcolor: 'colorBackground03',
    width: 1,
    borderColor: 'transparent',
    borderBottom: 3,
    borderTop: 3,
    borderLeft: 3,
    borderRight: 3,
  })}

  &:is(button)[aria-selected=true] {
    ${s({
      borderColor: 'colorInteractionSelectedState',
    })}
  }

  &:is(button):hover {
    ${s({
      borderColor: 'colorInteractionButtonHover',
    })}
  }
`

const StyledFavButton = styled(FavouriteButton)`
  ${s({
    position: 'absolute',
    top: 8,
    right: 8,
  })}
`

const ImageItem: FC<{
  title: string
  url: string
  zoomUrl?: string
  p?: number
  selected?: boolean
  onClick?: () => void
}> = ({ title, url, zoomUrl, p, selected, onClick, ...props }) => {
  return (
    <StyledImageContainer
      onClick={onClick}
      as={onClick ? 'button' : 'div'}
      aria-selected={selected}
      {...props}
    >
      <StyledImage
        aspectRatio={1}
        src={url}
        zoomSrc={zoomUrl}
        title={title}
        p={p}
        loading={selected ? 'eager' : 'lazy'}
      />
    </StyledImageContainer>
  )
}

type Props = {
  productId: string
  title: string
  images: Array<{ url: string }>
  initialSelectedIndex: number
  isFavourited: boolean
  onFavouriteToggle: () => void
  onImageSelected?: (event: { index: number }) => void
}

export const ImageCarouselVariant: FC<Props> = ({
  productId,
  title,
  images,
  initialSelectedIndex,
  isFavourited,
  onFavouriteToggle,
  onImageSelected,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    setSelectedIndex(initialSelectedIndex)
  }, [initialSelectedIndex])

  return (
    <Flex
      mb={6}
      gap={spacing(6)({ theme })}
      flexDirection={'column'}
      maxWidth="30.25rem"
      margin="0 auto"
    >
      <Box
        position={'relative'}
        data-product-id={productId}
        className="product-image-carousel"
      >
        <ImageItem
          title={title}
          url={images[selectedIndex].url}
          zoomUrl={images[selectedIndex].url}
          p={8}
        />
        <StyledFavButton
          title={title}
          isFavourited={isFavourited}
          onFavouriteToggle={onFavouriteToggle}
          isCard
        />
      </Box>
      <StyledCarousel>
        {images.map((image, index) => (
          <Box key={index} width={{ xs: '5rem', md: '6rem', lg: '7.35rem' }}>
            <ImageItem
              title={title}
              url={image.url}
              p={4}
              selected={selectedIndex === index}
              onClick={() => {
                setSelectedIndex(index)
                onImageSelected?.({ index })
              }}
            />
          </Box>
        ))}
      </StyledCarousel>
    </Flex>
  )
}
