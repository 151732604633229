export const FORMAT_POST_CARD = 'POST_CARD'
export const FORMAT_SQUARE_CARD = 'SQUARE_CARD'
export const FORMAT_LANDSCAPE_CARD = 'LANDSCAPE_CARD'
export const FORMAT_PORTRAIT_CARD = 'PORTRAIT_CARD'

export const CARD_INSIDE_LEFT_INDEX = 1
export const CARD_INSIDE_RIGHT_INDEX = 2

export const GROUP_CARD_IMAGES: { [key: string]: string[] } = {
  [FORMAT_SQUARE_CARD]: [
    'https://images.ctfassets.net/3m6f3dx67i1j/2FgFPkkDXcI1NW3shyEixY/acae00c51079184c8a8aeac416105b08/square-left.jpg',
    'https://images.ctfassets.net/3m6f3dx67i1j/chw8QyrLvPe6TFXmY9j2m/920ee19bcb8689e2fb60b4d2712a19c6/square-right.jpg',
  ],
  [FORMAT_LANDSCAPE_CARD]: [
    'https://images.ctfassets.net/3m6f3dx67i1j/50XGUztU2hCkeGhJr7y9ma/34772695b58a1a09be61c244a391ac29/landscape-left.jpg',
    'https://images.ctfassets.net/3m6f3dx67i1j/5YHRJR9G2ekhF3PtySjtQB/d3cb23a6743157b7541e40edb7279212/landscape-right.jpg',
  ],
  [FORMAT_PORTRAIT_CARD]: [
    'https://images.ctfassets.net/3m6f3dx67i1j/3ndc1B1yV6QCsvCqhJMTLh/c0d27164c4e710757766e670d96670cd/portrait-left.jpg',
    'https://images.ctfassets.net/3m6f3dx67i1j/7fzrI9UA402utZVoxWcZl2/5e31d4891c55177f7ba7e7b743c3706d/portrait-right.jpg',
  ],
}
