import React, { FC } from 'react'
import { Box, Flex, Text } from '@moonpig/launchpad-components'
import {
  IconSystemChevronUp,
  IconSystemChevronDown,
} from '@moonpig/launchpad-assets'
import { ThemeInterface, colorValue } from '@moonpig/launchpad-theme'
import { MenuButton as ReachButton } from '@reach/menu-button'
import styled from 'styled-components'
import {
  DROPDOWN_ITEM_BORDER_RADIUS,
  DROPDOWN_ITEM_HEIGHT,
  DROPDOWN_ITEM_ICON_SIZE,
} from './constants'

const BorderedBox = styled(Box)`
  border: ${({ theme }: { theme: ThemeInterface }) =>
    `2px solid ${colorValue('colorBlack40')({ theme })}`};

  box-sizing: border-box;
  border-radius: ${DROPDOWN_ITEM_BORDER_RADIUS};

  &:hover {
    background-color: ${colorValue('colorBackground02')};
  }
`

const StyledDropdownMenuButton = styled(ReachButton)`
  user-select: none;
  width: 100%;
  min-height: ${DROPDOWN_ITEM_HEIGHT};
  color: ${colorValue('colorTextHeadline')};
`

const StyledIconWrapper = styled(Flex)`
  align-items: center;
  width: ${DROPDOWN_ITEM_ICON_SIZE};

  svg {
    width: 100%;
  }
`

export const DropdownMenuButton: FC<{
  item: {
    title: string
    subtitle?: string
    icon?: JSX.Element
  }
  isExpanded: boolean
}> = ({ item, isExpanded }) => {
  const { title, subtitle, icon } = item

  return (
    <BorderedBox>
      <StyledDropdownMenuButton data-testid="shared-products-dropdown-button">
        <Flex pl={4} pr={6} py={4}>
          <StyledIconWrapper>{icon}</StyledIconWrapper>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            flexGrow={3}
            pl={7}
          >
            <Text typography="typeButtonLabel" textAlign="left">
              {title}
            </Text>
            {subtitle && <Text mb={0}>{subtitle}</Text>}
          </Flex>
          <Flex justifyContent="center" alignItems="center" ml={4}>
            {isExpanded ? (
              <IconSystemChevronUp width={24} />
            ) : (
              <IconSystemChevronDown width={24} />
            )}
          </Flex>
        </Flex>
      </StyledDropdownMenuButton>
    </BorderedBox>
  )
}
