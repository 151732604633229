import React from 'react'
import {
  Image,
  Box,
  Rating,
  CustomLink,
  LinkAsType,
  ImageProps,
  Link,
  Pill,
  HeartToggle,
  Flex,
} from '@moonpig/launchpad-components'
import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useProductsLocaleText } from '../../../locale'
import { useLocaleText } from '../ProductTile.locale'
import { HeartIcon } from '../HeartIcon'
import { PillVariant } from '../../../types'
import { SponsoredPill } from '../../SponsoredPill'

const ANIMATION_SETTINGS = '200ms ease-in-out'
const TITLE_HEIGHT = '48px'

const StyledZoomingImageFrame = styled.div`
  overflow: hidden;
`

const StyledZoomingImage = styled(Image)`
  ${s({ borderRadius: 2 })};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: transform ${ANIMATION_SETTINGS};
`

const StyledAnchorOverlay = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
`

const StyledTile = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform ${ANIMATION_SETTINGS};
  ${s({
    bgcolor: 'colorBackground01',
    borderRadius: 2,
    boxShadow: 2,
  })};

  &::before {
    content: '';
    ${s({ borderRadius: 2, boxShadow: 4 })}
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    opacity: 0;
    transition: opacity ${ANIMATION_SETTINGS};
    pointer-events: none;
  }

  ${breakpointUp('md')} {
    &:hover {
      text-decoration: none;
      ${({ theme }) => `transform: translateY(-${theme.spacing[3]}px);`}

      &::before {
        opacity: 1;
      }

      &.mp-styled-zooming-image {
        transform: scale(1.1);
      }
    }
  }
`

const StyledPriceDetailsContainer = styled(Flex)`
  order: 2;
  flex-flow: column;
  align-items: center;
  row-gap: 4px;
  ${s({
    padding: { xs: 4, md: 6 },
    marginTop: { xs: 4, md: 6 },
  })}
`

const StyledPriceContainer = styled(Flex)`
  column-gap: 8px;
  align-items: baseline;
  & > * {
    margin-bottom: 0;
  }
`

const StyledPrice = styled.p`
  ${s({
    typography: 'typeBodyLabel',
  })};
  ${breakpointUp('md')} {
    ${s({
      typography: 'typeDisplay06',
    })};
  }
`

const StyledFullPrice = styled.p`
  ${s({
    fontWeight: 'bold',
    color: 'colorBlack70',
    typography: 'typeBodyCaption',
  })};
  ${breakpointUp('md')} {
    ${s({
      typography: 'typeBodyLabel',
    })};
  }
`

const StyledDiscountDetails = styled.span`
  ${s({
    fontWeight: 'bold',
    color: 'colorFeedbackSuccess',
    typography: 'typeBodyCaption',
  })};
  ${breakpointUp('md')} {
    ${s({
      typography: 'typeBodyLabel',
    })};
  }
`

const StyledTitle = styled.p`
  ${s({
    typography: 'typeBodyText',
    paddingX: 6,
    paddingY: 0,
    marginTop: 6,
    order: 1,
  })}
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  height: ${TITLE_HEIGHT};
`

const StyledCallToAction = styled(CustomLink)`
  ${s({
    typography: 'typeBodyLabel',
    px: 4,
    pt: 0,
    pb: 6,
    mb: 0,
    color: 'colorInteractionButton',
  })}
  display: inline-block;
  position: relative;
  order: 3;
  pointer-events: ${({ as }) => (as === 'p' ? 'none' : 'inherit')};
`

const StyledOurPickWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
`

const StyledHeartToggleWrapper = styled.div`
  ${s({
    position: 'absolute',
    zIndex: 1,
    top: 4,
    right: 4,
  })}
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StyledCompAs = keyof JSX.IntrinsicElements | React.ComponentType<any>

type Labels = {
  moreInformation?: string
  addToBasket?: string
}

type ProductPill = {
  displayLabel: string
  displayVariant: PillVariant
}

type NonCardProductTileProps = {
  href: string
  title: string
  price: string
  fullPrice?: string | null
  discountedPercentage?: number | null
  image: {
    src: ImageProps['src']
    alt: string
  }
  productId: string
  rating?: {
    score: number
    count?: number
  }
  priceAs?: StyledCompAs
  fullPriceAs?: StyledCompAs
  titleAs?: StyledCompAs
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  linkAs?: LinkAsType
  ref?: React.Ref<HTMLDivElement>
  /** Determines the favourite toggle state props */
  favourite?: {
    /** State of the favourite toggle */
    isSelected?: boolean
    /** Callback function to change the state of the favourite toggle */
    onSelect: (e: React.MouseEvent<HTMLButtonElement>) => void
  }
  labels?: Labels
  ourPick?: boolean
  sponsored?: boolean
  pill?: ProductPill
}

export const NonCardProductTile = React.forwardRef(
  (props: NonCardProductTileProps, ref?: React.Ref<HTMLDivElement>) => {
    const {
      price,
      fullPrice,
      discountedPercentage,
      title,
      rating,
      image,
      href,
      priceAs,
      fullPriceAs,
      titleAs,
      onClick,
      linkAs,
      favourite,
      labels,
      ourPick,
      pill,
      sponsored,
      productId,
      ...rest
    } = props

    const localiseText = useLocaleText()
    const localiseRatingText = useProductsLocaleText()

    const moreInformationText =
      labels?.moreInformation ??
      localiseText('non_card_product_tile.more_information')
    const addToBasketText =
      labels?.addToBasket ?? localiseText('common.add_to_basket_cta')
    const sanitisedTitle = title.toLowerCase().replace(/\s/g, '-')

    const priceContainerId = `price-container-${sanitisedTitle}`
    const priceId = `price-${sanitisedTitle}`
    const fullPriceId = `full-price-${sanitisedTitle}`
    const discountDetailsId = `discount-details-${sanitisedTitle}`

    const showDiscountDetails = fullPrice && discountedPercentage

    const totalRatingsTextId = `rating-${sanitisedTitle}`

    const explainPrice = () => {
      if (!fullPrice || !discountedPercentage) {
        return localiseText('common.price_explanation', price)
      }

      return localiseText(
        'common.price_with_discount_explanation',
        price,
        fullPrice,
        discountedPercentage,
      )
    }

    return (
      <StyledTile
        data-testid="shared-products-non-card-product-tile"
        ref={ref}
        {...rest}
      >
        {favourite && (
          <StyledHeartToggleWrapper>
            <HeartToggle
              isToggled={!!favourite.isSelected}
              onToggle={(e: React.MouseEvent<HTMLButtonElement>) =>
                favourite.onSelect(e)
              }
              ariaLabel={`${
                favourite.isSelected
                  ? localiseText(
                      'non_card_product_tile.remove_from_favourites',
                      title,
                    )
                  : localiseText(
                      'non_card_product_tile.add_to_favourites',
                      title,
                    )
              }`}
            />
          </StyledHeartToggleWrapper>
        )}
        <StyledZoomingImageFrame>
          <StyledZoomingImage
            className="mp-styled-zooming-image"
            {...image}
            aspectRatio={1}
          />
        </StyledZoomingImageFrame>
        <StyledTitle
          aria-describedby={`${priceId} ${totalRatingsTextId}`}
          as={titleAs}
        >
          {title}
        </StyledTitle>
        <Box
          order={1}
          mx="auto"
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          mt={6}
        >
          {sponsored && (
            <Box zIndex={1}>
              <SponsoredPill />
            </Box>
          )}
          {!sponsored && pill && (
            <Pill label={pill.displayLabel} variant={pill?.displayVariant} />
          )}
          {rating && rating.count !== 0 && !sponsored && !pill ? (
            <Rating
              rating={rating.score}
              totalRatingsText={
                rating.count
                  ? `(${localiseRatingText(
                      'rating.rating_count',
                      rating.count,
                    )})`
                  : undefined
              }
              totalRatingsScreenReaderText={
                rating.count
                  ? localiseRatingText('rating.total_reviews')
                  : undefined
              }
              starSize={24}
              totalRatingsTextId={totalRatingsTextId}
            />
          ) : null}
        </Box>

        <StyledPriceDetailsContainer
          aria-label={explainPrice()}
          data-testid={priceContainerId}
        >
          <StyledPriceContainer>
            <StyledPrice as={priceAs} id={priceId} data-testid={priceId}>
              {price}
            </StyledPrice>
            {showDiscountDetails && (
              <StyledFullPrice
                as={fullPriceAs}
                data-testid={fullPriceId}
                style={{
                  textDecoration: 'line-through',
                }}
              >
                {fullPrice}
              </StyledFullPrice>
            )}
          </StyledPriceContainer>
          {showDiscountDetails && (
            <StyledDiscountDetails data-testid={discountDetailsId}>
              {localiseText('common.discount_details', discountedPercentage)}
            </StyledDiscountDetails>
          )}
        </StyledPriceDetailsContainer>

        {!linkAs && (
          <StyledCallToAction
            as="p"
            className="add-to-basket-cta"
            data-product-id={productId}
          >
            {localiseText('common.add_to_basket_cta')}
          </StyledCallToAction>
        )}

        <StyledAnchorOverlay
          onClick={onClick}
          href={href}
          title={onClick ? moreInformationText : addToBasketText}
        >
          {ourPick && (
            <StyledOurPickWrapper>
              <Pill label={localiseText('our_pick')} icon={<HeartIcon />} />
            </StyledOurPickWrapper>
          )}
        </StyledAnchorOverlay>

        {linkAs && (
          <StyledCallToAction
            to={href}
            linkAs={linkAs}
            aria-hidden={!onClick}
            className="add-to-basket-cta"
            data-product-id={productId}
          >
            {localiseText('common.add_to_basket_cta')}
          </StyledCallToAction>
        )}
      </StyledTile>
    )
  },
)

NonCardProductTile.displayName = 'NonCardProductTile'
