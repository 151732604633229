import { Module } from '../types'

export const createGroupCardProjectMobileConfiguration = (): Module[] => {
  return [
    { __typename: 'ModuleImages' },
    {
      __typename: 'ModuleVariants',
      type: 'radio',
    },
    { __typename: 'ModuleDispatch' },
    { __typename: 'ModuleMoreInfo' },
    { __typename: 'ModulePeopleAlsoViewed' },
    { __typename: 'ModuleButtons' },
  ]
}

export const createGroupCardProjectDesktopConfiguration = (): Module[] => {
  return [
    {
      __typename: 'ModuleTwoColumn',
      left: [
        { __typename: 'ModuleImages' },
        { __typename: 'ModulePeopleAlsoViewed' },
      ],
      right: [
        {
          __typename: 'ModuleVariants',
          type: 'radio',
        },
        { __typename: 'ModuleDispatch' },
        { __typename: 'ModuleButtons' },
        { __typename: 'ModuleMoreInfo' },
      ],
    },
  ]
}
